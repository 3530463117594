import React, {useContext, useState, useEffect} from "react";
import styles from "./EditItem.module.css";
import { withRouter } from 'react-router-dom';
import { AppContext } from '../../App';
import { useParams } from 'react-router-dom';
import ApiManager from '../../manager/ApiManager';
import CustomButton from '../CustomButton/CustomButton';

const EditItem = withRouter(props => {
    let { state, dispatch } = useContext(AppContext);
    let { isSubCategory, history } = props;
    let { itemId } = useParams();
    let [ item, setItem ] = useState({sku: "", nameChi: "", nameEn: "", unitTxt: "", price: 0, categoryId: ""});

    let getItemReducer = (acc, category) => {
        let matchedItem = category.items.find(item => item._id === itemId);
        if (matchedItem != null) {
            acc = {...matchedItem, categoryId: category._id};
        }
        return acc;
    }

    useEffect(() => {
        if (state.categories != null) {
            let targetItem;

            if (isSubCategory) {
                targetItem = state.categories.reduce((acc, category) => {
                    let matchedItem = category.subCategories.reduce(getItemReducer, null);
                    if (matchedItem != null) {                        
                        acc = matchedItem;
                    }
                    return acc;
                }, null);

            } else {
                targetItem = state.categories.reduce(getItemReducer, null);                
            }
            
            if (targetItem.sku == null) {
                targetItem.sku = "";
            }
            setItem({sku: targetItem.sku, nameChi: targetItem.nameChi, nameEn: targetItem.nameEn, unitTxt: targetItem.unitTxt, price: targetItem.price, categoryId: targetItem.categoryId});   
        }
    }, [])


    const submit = async () => {   
        let response = await ApiManager.editItem(item.sku, item.nameChi, item.nameEn, item.unitTxt, item.price, itemId, isSubCategory);
        if (response.result) {
            alert("成功修改");
            history.push("/admin");
        } else {
            alert("提交失敗");
        }

    }

    let deleteItem = async () => {
        let confirmDelete = window.confirm(`確定要删除${item.nameChi}?`);
        if (confirmDelete) {
            let response = await ApiManager.deleteItem(item.categoryId, itemId, isSubCategory);
        
            if (response.result) {
                alert("成功删除");
                props.history.push("/admin");
            } else {
                alert("删除失敗");
            }
        }
    }

    const cancel = async () => {
        props.history.goBack();
    }

    return (
        <div>
            <div className={styles.title}>修改貨品</div>
            <div className={styles.row}>
                SKU: <input type="text" value={item.sku} onChange={val => {                            
                    setItem({...item, sku: val.target.value})}
                }/>
            </div>
            {!isSubCategory &&
            <>
            <div className={styles.row}>
                中文名稱: <input type="text" value={item.nameChi} onChange={val => {                            
                    setItem({...item, nameChi: val.target.value})}
                }/>
            </div>
            <div className={styles.row}>
                英文名稱: <input type="text" value={item.nameEn} onChange={val => {
                    setItem({...item, nameEn: val.target.value})}
                }/>
            </div>
            </>
            }
            <div className={styles.row}>
                容量: <input type="text" value={item.unitTxt} onChange={val => {
                    setItem({...item, unitTxt: val.target.value})}
                }/>
            </div>
            <div className={styles.row}>
                價錢: <input type="text" value={item.price} onChange={val => {
                    setItem({...item, price: val.target.value})}
                }/>
            </div>

            <div className={styles.btns}>
                <CustomButton onClick={submit}>更新</CustomButton>
                <CustomButton onClick={deleteItem} style={{marginLeft: '10px', background: 'red'}}>删除此貨品</CustomButton>
                <CustomButton onClick={cancel} style={{marginLeft: '10px'}}>取消</CustomButton>
            </div>    
        </div>
    )
});

export default EditItem;