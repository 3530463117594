import React, {useEffect, useState, useContext} from "react";
import { AppContext } from '../../App';
import ApiManager from '../../manager/ApiManager';
import styles from "./EditProductList.module.css";
import Category from '../Category/Category';
import { withRouter } from 'react-router-dom';
import CustomButton from "../CustomButton/CustomButton";

const EditProductList = withRouter(props => {
    let {state, dispatch} = useContext(AppContext);

    useEffect(() => {
        const getInfo = async() => {
            let categories = await ApiManager.getCategories();                        
            dispatch({type: "setupCategories", payload: categories});
        }
        getInfo();
    }, [])

    const editContent = () => {
        props.history.push("/admin/editContent");
    }

    const addCategory = () => {
        props.history.push("/admin/addNewCategory/");
    }

    return (
        <>                                
        <div className={styles.category}>
            <div className={styles.topBtns}>
                <CustomButton onClick={editContent}>更新內容</CustomButton>
                <CustomButton onClick={addCategory} style={{marginLeft: '10px'}}>增加新類別</CustomButton>
            </div>

            {state.categories != null && state.categories.map(category => (
                <Category category={category} key={category._id} layer={0} edit={true}></Category>
            ))}        
        </div>
        </>
    )
});

export default EditProductList;