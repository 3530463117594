import React, {useEffect, useState} from "react"
import styles from './Header.module.css';
import HeadBanner from '../HeadBanner/HeadBanner';
import ApiManager from '../../manager/ApiManager';


const Header = () => {    
    let [header, setHeader] = useState("");

    useEffect(() => {
        const getContent = async () => {
            let content = await ApiManager.getContent();            
            let header = content.header;
            setHeader(header);
        }

        getContent();
    }, []);

    return (
        <div>
            <HeadBanner></HeadBanner>
            <div style={{padding: '110px 0px 20px 0px'}}>       
                <div style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: header}} />                
            </div>
        </div> 
    )
}

export default Header;