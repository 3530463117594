import React, {useContext, useEffect, useState} from "react"
import { AppContext } from '../../App';
import ApiManager from '../../manager/ApiManager';
import styles from "./Login.module.css"
import { withRouter } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';

const Login = withRouter(props => {
    let {state, dispatch} = useContext(AppContext);
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");

    const login = async () => {
        let loginRes = await ApiManager.login(username, password);
        if (loginRes.result) {
            localStorage.setItem("token", loginRes.user.token);
            localStorage.setItem("username", loginRes.user.username);
            localStorage.setItem("role", loginRes.user.role);
            dispatch({type: "login", payload: {token: loginRes.user.token, username: loginRes.user.username, role: loginRes.user.role}});
        } else {
            alert("登入失敗");
        }
    } 

    return (
        <div className={styles.container}>
            <div className={styles.logoRow}>
                <img src="./img/poise.jpg" height={150}/>
            </div>
            <div className={styles.row}>
                登入名稱: <input type="text" value={username} onChange={val => setUsername(val.target.value)}/>
            </div>
            <div className={styles.row}>
                登入密碼: <input type="password" value={password} onChange={val => setPassword(val.target.value)}/>
            </div>            
            <div className={styles.loginRow}>
                <CustomButton onClick={login}>登入</CustomButton>
            </div>     
            
        </div>
    )

});

export default Login;