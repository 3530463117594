import React, { useState, useEffect, useContext } from "react";
import styles from "./AddNewItem.module.css";
import ApiManager from '../../manager/ApiManager';
import { useParams, withRouter } from "react-router-dom";
import { AppContext } from "../../App";
import CustomButton from '../CustomButton/CustomButton';

const AddNewItem = withRouter(props => {
    let {state, dispatch} = useContext(AppContext);
    let {categoryId} = useParams();
    let {isSubCategory} = props;

    let [newItem, setNewItem] = useState({sku: '', nameChi: '', nameEn: '', unitTxt: '', price: 0});

    useEffect(() => {        
        if (isSubCategory && state.categories != null) {                        
            let {nameChi, nameEn} = state.categories.reduce((acc, category) => {
                let matchedSubCategories = category.subCategories.filter(subCategory => subCategory._id == categoryId);
                if (matchedSubCategories.length > 0) {
                    let matchedSubCategory = matchedSubCategories[0];
                    acc = {nameChi: matchedSubCategory.nameChi, nameEn: matchedSubCategory.nameEn};
                }
                return acc;
            }, {});
            
            setNewItem({...newItem, nameChi, nameEn});
        }
    }, [state])

    let addItem = async () => {
        let response = await ApiManager.addItem(newItem.sku, newItem.nameChi, newItem.nameEn, newItem.unitTxt, newItem.price, categoryId, isSubCategory);
        if (response.result) {
            alert("成功新增貨品");
            props.history.goBack();  
            // setNewItem({...newItem, sku: '', unitTxt: '', price: 0});            
        } else {
            alert("提交失敗");
        }        
    }

    const cancel = async () => {
        props.history.goBack();
    }

    return (
        <div style={{padding: '10px 0px'}}>
            <div className={styles.title}>新增貨品</div>
            <div className={styles.row}>
                SKU: <input type="text" value={newItem.sku} onChange={val => {    
                    let skuVal = val.target.value;  
                    let updateObj = {...newItem};                                  
                    if (skuVal.indexOf("-") > -1) {                        
                        let unitTxt = skuVal.split("-")[skuVal.split("-").length - 1];
                        updateObj = {...updateObj, unitTxt: unitTxt};                        
                    }                     
                    setNewItem({...updateObj, sku: val.target.value})}
                }/>
            </div>
            { !isSubCategory &&
            <>
            <div className={styles.row}>
                中文名稱: <input type="text" value={newItem.nameChi} onChange={val => {                            
                    setNewItem({...newItem, nameChi: val.target.value})}
                }/>
            </div>
            <div className={styles.row}>
                英文名稱: <input type="text" value={newItem.nameEn} onChange={val => {
                    setNewItem({...newItem, nameEn: val.target.value})}
                }/>
            </div>
            </>
            }
            <div className={styles.row}>
                容量: <input type="text" value={newItem.unitTxt} onChange={val => {
                    setNewItem({...newItem, unitTxt: val.target.value})}
                }/>
            </div>
            <div className={styles.row}>
                價錢: <input type="text" value={newItem.price} onChange={val => {
                    setNewItem({...newItem, price: val.target.value})}
                }/>
            </div>
            <div className={styles.btns}>
                <CustomButton onClick={addItem}>提交</CustomButton>
                <CustomButton onClick={cancel} style={{marginLeft: '10px'}}>取消</CustomButton>
            </div>   
        </div>
    )
});

export default AddNewItem;