import React, {useEffect} from "react";
import styles from "./Privacy.module.css";
import Footer from '../Footer/Footer';
import { withRouter } from 'react-router-dom';
import HeadBanner from '../HeadBanner/HeadBanner';

const Privacy = withRouter(props => {

    return (
        <div className={styles.container}>
            <HeadBanner></HeadBanner>
            <div className={styles.content}>                
                <h2>網站版權</h2>
                
                <p>Poise所載的全部內容及資料，包括但不限於網站的美術設計、瀏覽連繫及軟件程式，均屬「Poise」（下稱「本網站」或「本服務」）所有，且受版權保護。任何人士如未獲本網站事先給予書面許可，一律禁止轉載、發放或擅用本網站的任何內容或資料。</p>

                <h3>使用條款及細則</h3>
                <p>請在使用本網站前細閱下列各項條款。使用者（「用戶」或「您」）使用本網站，即表示接受下列各項使用條款。如您不接受該等使用條款，則應離開本網站。</p>

                <h3>1. 擁有權</h3>
                <p>本網站的全部內容（包括所有圖像、文字、影像、照片、插圖及其設計、選取及編排，而且不局限於此範圍）的版權及／或商標權及／或知識產權均由本網站擁有或取得授權使用，並受相關法例保障。</p>

                <h3>2. 免責條款聲明</h3>
                <p>本網站對於任何包含於、經由、或聯結、下載或從任何與本網站有關服務（以下簡稱「服務」）所獲得之資訊、內容或廣告（以下簡稱「資料」），不聲明或保證其內容之正確性或可靠性；並且，對於您透過「服務」上之廣告、資訊或要約而展示、購買或取得之任何產品、資訊或資料（「產品」），本網站亦不負品質保證之責任。您於此接受並承認信賴任何「資料」所生之風險應自行承擔。本網站，有權但無此義務，改善或更正在「服務」或「資料」任何部分之錯誤或疏失。本網站之服務與資料是基於「現況」及「現有」提供，而且本網站明確地表示拒絕對於「服務」、「資料」或「產品」給予任何明示或暗示之保證，包括但不限於，得為商業使用或適合於特定目的之保證。本網站對於因「服務」、「資料」或「產品」所生之任何直接、間接、附帶的或因此而導致之衍生性損失概不負責。</p>
                <p>本網站尊重他人的任何權利（包括智慧財產權），同時也要求我們的使用者也尊重他人之權利。本網站在適當情況下，得自行決定終止侵害或違反他人權利之使用者的帳號。如果您認為您的作品被非法重製，請按我們的著作權及著作權代理網頁之指示辦理。</p>

                <h3>3. 有限許可證及相關限制</h3>
                <p>您將獲授予有限許可證，可將本網站所載資料下載至個人電腦及列印出來，但僅 限於用作個人及非商業用途，唯一切版權、商標權及其他所有權均不得受侵犯。於任何情況下使用該等資料時，均須註明資料來源為本網站。此外嚴禁在任何其他網站使用本網站內容。您須接受並遵守本使用條款的一切規定，方可獲授予上述有限許可證。如未經本網站書面事先批准，以任何其他方式使用本網站任何內容，包括複製（上述所提及的用途除外）、修改、分發或複印，均屬侵犯其中之一或全部營運者的知識產權。</p>

                <h3>4 . 您的註冊義務</h3>
                <p>依本服務註冊表格之提示，提供您本人正確、準確、最新及完整的資料（以下簡稱「登記資料」）；</p>
                <p>維持並立即更新「登記資料」，確保其為正確、精確、最新及完整。若您提供任何錯誤、不實、過時、不完整或有誤導成分的資料，或者本網站有合理的理由懷疑前開資料為錯誤、不實、過時、不完整或有誤導成分的資料，本網站有權暫停或終止您的帳號，並拒絕您於現在和未來使用本服務。</p>
 
                <h3>5. 私隱</h3>
                <p>由本網站保存並與您有關的「登記資料」及其他相關資料均受到個人資料條例和本網站「私隱條款」之規管。</p>

                <h3>6. 會員帳號及密碼</h3>
                <p>完成本站的登記程序之後，您將收到一個密碼及帳號。維持密碼及帳號的機密安全，是您的責任。利用該密碼及帳號所進行的一切行動，您將負完全的責任。</p>

                <p>您同意以下事項：</p>
                <p>您的密碼或帳號遭到未獲授權的使用，或者其他任何安全問題發生時，您將立即通知本網站；</p>
                <p>每次連線完畢，均結束您的帳號使用。您未能遵守本項規定所衍生之任何損失或損害，本網站無法也不予負責。</p>
 
                <h3>7. 使用及儲存服務</h3>
                <p>若本網站維持或傳送之任何訊息及其他通訊和其他「內容」刪除或未予儲存，您同意本網站毋須承擔任何責任。您亦同意，長時間未使用的帳號，本網站有權關閉。您也同意，本網站有權依其自行之考量，不論有否通知您，可隨時變更這些一般措施及限制。</p>

                <h3>8. 服務之修改</h3>
                <p>本網站有權在有發出通知或沒有發出通知的形況下，修改、暫停或永遠終止本服務（或其任何部分）。您同意本網站將不會因爲任何修改、暫停或終止本服務而向您或第三者負有責任。</p>

                <h3>9. 終止</h3>
                <p>您同意本網站得基於其自行之考量，因本網站認為您已經違反本服務條款的明文規定及精神，或任何理由，終止您的密碼、帳號（或其任何部分）或本服務之使用，並將本服務內任何內容加以移除並刪除。本網站無論有否通知您，都可以依其自行之考量隨時終止本服務或其任何部分。您同意依本服務條款任何規定提供之服務，無需事先通知您即可暫停或終止，您承認並同意，本網站得立即關閉或刪除您的帳號及您帳號中所有相關資料及檔案，及/或禁止前開檔案和本服務之使用。此外，您同意若您被暫停或終止進入服務，或您的帳戶或有關的資料和檔案被凍結或刪除時，本網站對您或任何第三人均不承擔責任。</p>

                <h3>10. 查詢及建議</h3>
                <p>各用戶如對此網站所提供的內容有疑問、對所載的某些資料有問題需要提出、發現有遺漏之處，或認為有關資料可以更為清楚的方式表達，請聯絡我們。</p>

            </div>            
            <Footer></Footer>
        </div>
    )
});

export default Privacy;