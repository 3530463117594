export const initialState = {
    authenticated: false,
}

const reducer = (state, action) => {
    switch (action.type) {
        case "login":                         
            let {username, role} = action.payload;
            return {...state, authenticated: true, username, role};            
        case "logout":
            localStorage.clear();
            return  {...state, authenticated: false, username: null, role: null};            
        case "setupCategories":
            let categories = action.payload;            
            return {...state, categories: categories};
        case "updateItem":
            let newItem = action.payload;            
            let tmpCategories = state.categories.map(category => {
                let items = category.items.map(item => {
                    if (item._id == newItem._id) {
                        item = {...newItem};
                    }

                    return item;
                });

                category.items = [...items];

                let subCategories = category.subCategories.map(subCategory => {
                    let items = subCategory.items.map(item => {
                        if (item._id == newItem._id) {
                            item = {...newItem};
                        }
    
                        return item;
                    });
                    
                    subCategory.items = [...items];

                    return subCategory;
                })

                category.subCategories = [...subCategories];

                return category;
            })
            return {...state, categories: [...tmpCategories]};
        case "updateCategory":
            let newCategories = [...state.categories];            
            let newCategory = action.payload;   
            newCategories = newCategories.map(category => {
                if (category._id === newCategory._id) {                    
                    category = {...newCategory};
                }
                                
                if (category.subCategories.length > 0) {
                    let found = false;
                    let newSubCategories = category.subCategories.map(subCategory => {
                        if (subCategory._id === newCategory._id) {
                            found = true;
                            subCategory = {...newCategory};
                        }

                        return subCategory;
                    })
                    if (found) {
                        category.subCategories = [...newSubCategories];
                    }
                }
                return category;
            });
            
            return {...state, categories: newCategories};
        default:
            throw new Error("Unsupported aciton");        
    }
}

export default reducer;