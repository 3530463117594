export const DISCOUNT_SHIPPING_ENTITLED_AMOUNT = 300;
export const FREE_SHIPPING_ENTITLED_AMOUNT = 600;
export const SHIPPING_FEE = 28;
export const DISCOUNTED_SHIPPING_FEE = 15;
export const WHATSAPP_CONTACT = "85293837664";
export const DELIVERY_METHOD = [
    "順豐快遞上門",
    "順豐智能櫃",
    "順豐點",
    "順豐服務中心"
]
export const PAYME_LINK = "https://payme.hsbc/poise";
export const POISE_BLUE = "#137cef";