import React, {useContext, useEffect, useState} from "react"
import styles from "./EditContent.module.css"
import { withRouter } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';
import ApiManager from '../../manager/ApiManager';

const EditContent = withRouter(props => {
    let [header, setHeader] = useState("");

    useEffect(() => {
        const getContent = async () => {
            let content = await ApiManager.getContent();            
            let header = content.header;
            setHeader(header);
        }

        getContent();
    }, []);

    const updateHeader = async () => {
        let response = await ApiManager.updateContent("header", header);
        if (response.result) {
            alert("成功更新");
            props.history.goBack();
        } else {
            alert("更新失敗");
        }
    }

    const cancel = async () => {
        props.history.goBack();
    }

    return (
        <div>
            <div className={styles.title}>更新內容</div>
            <div className={styles.row}> 
                <textarea className={styles.input} value={header} onChange={val => setHeader(val.target.value)}></textarea>
            </div>
            <div className={styles.btn}>
                <CustomButton onClick={updateHeader}>更新</CustomButton>
                <CustomButton onClick={cancel} style={{marginLeft: '10px'}}>取消</CustomButton>
            </div>
        </div>
    )
});

export default EditContent;