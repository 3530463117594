import React from "react";
import styles from "./Footer.module.css";
import { withRouter, NavLink } from 'react-router-dom';


const Footer = withRouter(props => {
    let { history } = props;

    return (
        <div className={styles.container}>            
            <NavLink to="/privacy" className={styles.link}>網站版權</NavLink>            
        </div>
    )
});


export default Footer;