import React, {useEffect, useContext, useState} from "react";
import styles from "./EditCategory.module.css";
import { withRouter } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ApiManager from '../../manager/ApiManager';
import { AppContext } from '../../App';
import CustomButton from '../CustomButton/CustomButton';

const EditCategory = withRouter(props => {
    let { state, dispatch } = useContext(AppContext);
    let { isSubCategory } = props;
    let { categoryId } = useParams();
    let [ category, setCategory ] = useState({nameChi: "", nameEn: "", parentCategoryId: ""});    
    
    useEffect(() => {
        if (state.categories != null) {
            let targetCategory;

            if (isSubCategory) {
                targetCategory = state.categories.reduce((acc, category) => {                    
                    let matchedSubCategory = category.subCategories.find(subCategory => subCategory._id === categoryId);
                    if (matchedSubCategory != null) {                        
                        acc = {...matchedSubCategory, parentCategoryId: category._id};                        
                    }                    

                    return acc;
                }, {});                
            } else {
                let matched = state.categories.filter(category => category._id == categoryId);
                targetCategory = matched[0];                
            }
            
            setCategory({nameChi: targetCategory.nameChi, nameEn: targetCategory.nameEn, parentCategoryId: targetCategory.parentCategoryId});   
        }
    }, [])

    let submit = async () => {
        let response = await ApiManager.editCategory(category.nameChi, category.nameEn, categoryId, isSubCategory);
        if (response.result) {
            alert("成功修改");
            props.history.push("/admin");
        } else {
            alert("提交失敗");
        }
    }

    let deleteCategory = async () => {
        let confirmDelete = window.confirm(`確定要删除${category.nameChi}?`);
        if (confirmDelete) {
            let response;
            if (isSubCategory) {
                response = await ApiManager.deleteSubCategory(category.parentCategoryId, categoryId);
            } else {
                response = await ApiManager.deleteCategory(categoryId);
            }

            if (response.result) {
                alert("成功删除");
                props.history.push("/admin");
            } else {
                alert("删除失敗");
            }
        }

    }

    const cancel = async () => {
        props.history.goBack();
    }

    return (
        <>
        <div className={styles.title}>修改類別</div>        
        <div className={styles.row}>
            中文名稱: <input type="text" value={category.nameChi} onChange={val => {                            
                setCategory({...category, nameChi: val.target.value})
            }} />
        </div>
        <div className={styles.row}>
            英文名稱: <input type="text" value={category.nameEn} onChange={val => {
                setCategory({...category, nameEn: val.target.value})
            }} />
        </div>

        <div className={styles.btns}>
            <CustomButton onClick={submit}>更新</CustomButton>
            <CustomButton onClick={deleteCategory} style={{marginLeft: '10px', background: 'red'}}>删除此類別</CustomButton>
            <CustomButton onClick={cancel} style={{marginLeft: '10px'}}>取消</CustomButton>
        </div>   
        
        </>   
    )

});

export default EditCategory;