import React, {createContext, useContext, useReducer} from 'react';
import './App.css';
import ProductList from './components/ProductList/ProductList';
import OrderForm from './components/OrderForm/OrderForm';
import Admin from './components/Admin/Admin';
import reducer, {initialState} from './reducers/reducer';
import AddNewCategory from './components/AddNewCategory/AddNewCategory';
import { BrowserRouter, Switch, Route, Link} from "react-router-dom";
import AddNewItem from './components/AddNewItem/AddNewItem';
import EditCategory from './components/EditCategory/EditCategory';
import EditItem from './components/EditItem/EditItem';
import { useEffect } from 'react';
import Privacy from './components/Privacy/Privacy';
import Footer from './components/Footer/Footer';
import ScrollToTop from './util/ScrollToTop';
import EditContent from './components/EditContent/EditContent';

export const AppContext = createContext();
 
function App() {  
  let [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let username = localStorage.getItem("username");
    let role = localStorage.getItem("role");
    if (token != null) {
      dispatch({type: "login", payload: {token, username, role}});
    }
  }, [])
  return (
    <AppContext.Provider value={{state, dispatch}}>
      <BrowserRouter>
      <ScrollToTop />
      <div className="container">
        <Switch>
          <Route exact path="/">
            <ProductList></ProductList>   
            <OrderForm></OrderForm>
            <Footer></Footer>
          </Route>  
          <Route exact path="/admin">            
            <Admin></Admin>
          </Route>   
          <Route exact path="/admin/addNewCategory">            
            <AddNewCategory></AddNewCategory>
          </Route>     
          <Route exact path="/admin/addNewCategory/:categoryId">            
            <AddNewCategory></AddNewCategory>
          </Route>   
          <Route exact path="/admin/addNewCategoryItem/:categoryId">
            <AddNewItem isSubCategory={false}></AddNewItem>
          </Route>
          <Route exact path="/admin/addNewSubCategoryItem/:categoryId">
            <AddNewItem isSubCategory={true}></AddNewItem>          
          </Route>   
          <Route exact path="/admin/editCategory/:categoryId">
            <EditCategory isSubCategory={false}></EditCategory>          
          </Route>  
          <Route exact path="/admin/editSubCategory/:categoryId">
            <EditCategory isSubCategory={true}></EditCategory>          
          </Route>      
          <Route exact path="/admin/editCategoryItem/:itemId">
            <EditItem isSubCategory={false}></EditItem>          
          </Route> 
          <Route exact path="/admin/editSubCategoryItem/:itemId">
            <EditItem isSubCategory={true}></EditItem>          
          </Route>   
          <Route exact path="/admin/editContent">
            <EditContent></EditContent>
          </Route>   
          <Route exact path="/privacy">
            <Privacy></Privacy>          
          </Route>        
        </Switch>
      </div>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
