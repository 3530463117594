import React from "react";
import styles from "./CustomButton.module.css";

const CustomButton = (props) => {
    return (
        <div className={styles.btn} onClick={props.onClick} style={{...props.style}}>
            {props.children}
        </div>
    )
}

export default CustomButton;