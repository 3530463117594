import React, {useContext, useEffect, useState} from "react"
import { AppContext } from '../../App';
import ApiManager from '../../manager/ApiManager';
import AddNewCategory from "../AddNewCategory/AddNewCategory";
import AddNewItem from "../AddNewItem/AddNewItem";
import EditProductList from "../EditProductList.js/EditProductList";
import { withRouter } from 'react-router-dom';
import Login from "../Login/Login";
import styles from "./Admin.module.css";
import HeadBanner from '../HeadBanner/HeadBanner';

const Admin = withRouter((props) => {
    let {state, dispatch} = useContext(AppContext);
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    

    useEffect(() => {       
    }, []);

    const login = async () => {
        let loginRes = await ApiManager.login(username, password);
        if (loginRes.result) {
            localStorage.setItem("token", loginRes.user.token);
            localStorage.setItem("username", loginRes.user.username);
            localStorage.setItem("role", loginRes.user.role);
            dispatch({type: "login", payload: {token: loginRes.user.token, username: loginRes.user.username, role: loginRes.user.role}});
        } else {
            alert("登入失敗");
        }
    }   

    return (
        <div>                       
            <div>
            { !state.authenticated ? 
                <div className={styles.loginContainer}>
                    <Login></Login>  
                </div>
            :
               ""
            }        
            </div>

            { state.authenticated &&
            <div>                
                <HeadBanner></HeadBanner>                       
                <div style={{paddingTop: '110px'}}>                      
                    <EditProductList></EditProductList>                    
                </div>   
            </div>      
            }

            
        </div>
    )
});

export default Admin;