import CategoryModel from "../models/CategoryModel";
import ItemModel from "../models/ItemModel";
import { useContext } from 'react';
import { AppContext } from '../App';

const SERVER_API_URL = `${process.env.REACT_APP_SERVER_API_URL}`;

export default class ApiManager {

    static async getContent() {                
        let response = await fetch(`${SERVER_API_URL}/content`);
        let result = await response.json();        
        return result.value;
    }

    static async updateContent(part, value) {                
        let token = localStorage.getItem("token");            
        let postBody = {token, part, value};

        let response = await fetch(`${SERVER_API_URL}/content`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        });
        

        let result = await response.json();        
        return result;     
    }

    static addCategory = async (nameChi, nameEn, parentCategoryId) => {  
        let token = localStorage.getItem("token");

        let response = null;
        let postBody = {nameChi, nameEn, token};
        if (parentCategoryId == null) {            
            response = await fetch(`${SERVER_API_URL}/category`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postBody)
            });
        } else {            
            response = await fetch(`${SERVER_API_URL}/category/${parentCategoryId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postBody)
            });
        }

        let result = await response.json();        
        return result;        
    }

    static deleteCategory = async (categoryId) => {
        let token = localStorage.getItem("token");   
        let postBody = {token};

        let response = await fetch(`${SERVER_API_URL}/category/${categoryId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        });
        

        let result = await response.json();        
        return result;  
    }

    static deleteSubCategory = async (categoryId, subCategoryId) => {
        let token = localStorage.getItem("token");   
        let postBody = {token};

        let response = await fetch(`${SERVER_API_URL}/category/${categoryId}/${subCategoryId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        });
        

        let result = await response.json();        
        return result;  
    }

    static deleteItem = async (categoryId, itemId, isSubCategory) => {
        let token = localStorage.getItem("token");   
        let postBody = {token, isSubCategory};

        let response = await fetch(`${SERVER_API_URL}/item/${categoryId}/${itemId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        });
        

        let result = await response.json();        
        return result;  
    }

    static addItem = async (sku, nameChi, nameEn, unitTxt, price, categoryId, isSubCategory) => {    
        let token = localStorage.getItem("token");            
        let postBody = {sku, nameChi, nameEn, unitTxt, price, categoryId, isSubCategory, token};
       
        let response = await fetch(`${SERVER_API_URL}/item`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        });
        

        let result = await response.json();        
        return result;        
    }

    static editCategory = async(nameChi, nameEn, categoryId, isSubCategory) => {
        let token = localStorage.getItem("token");            
        let postBody = {nameChi, nameEn, isSubCategory, token};

        let response = await fetch(`${SERVER_API_URL}/category/${categoryId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        });
        

        let result = await response.json();        
        return result;        
    }

    static editItem = async(sku, nameChi, nameEn, unitTxt, price, itemId, isSubCategory) => {
        let token = localStorage.getItem("token");            
        let postBody = {sku, nameChi, nameEn, unitTxt, price, isSubCategory, token};

        let response = await fetch(`${SERVER_API_URL}/item/${itemId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        });
        

        let result = await response.json();        
        return result; 
    }

    static sortItems = (itemA, itemB) => {
        let itemAName = itemA.nameEn.toUpperCase();
        let itemBName = itemB.nameEn.toUpperCase();                
        
        if (itemAName > itemBName) {
            return 1;
        } else if (itemAName < itemBName) {
            return -1;
        } else { // same name, compare unitTxt
            let itemAUnitTxt = itemA.unitTxt;
            let itemBUnitTxt = itemB.unitTxt;
            
            let itemAUnit = 0;
            
            if (itemAUnitTxt.match(/\d+/g) != null && itemAUnitTxt.match(/\d+/g).length > 0) {
                let itemAUnitTxtString = itemAUnitTxt.match(/\d+/g)[0];                
                itemAUnit = parseInt(itemAUnitTxtString);                
            }

            let itemBUnit = 0;
            if (itemBUnitTxt.match(/\d+/g) != null && itemBUnitTxt.match(/\d+/g).length > 0) {                
                let itemBUnitTxtString = itemBUnitTxt.match(/\d+/g)[0];
                itemBUnit = parseInt(itemBUnitTxtString);
            }

            if (itemAUnit > itemBUnit) {
                return 1;
            } else if (itemAUnit < itemBUnit) {
                return -1;
            } else {
                return 0;
            }

        }
    };

    static sortCategory = (subCatA, subCatB) => {
        let nameA = subCatA.nameEn.toUpperCase();
        let nameB = subCatB.nameEn.toUpperCase();

        if (nameA > nameB) {
            return 1;
        } else if (nameA < nameB) {
            return -1;
        } else {
            return 0;
        }
    }

    static async getCategories() {
        let categories = []; 
        let response = await fetch(`${SERVER_API_URL}/category`);
        categories = await response.json();
        
        // sort category items
        let sortedCategories = categories.map(category => {

            // sort category items
            if (category.items.length > 0) {
                category.items.sort(ApiManager.sortItems);
            }
            
            
            if (category.subCategories.length > 0) {
                // sort subcategories
                category.subCategories.sort(ApiManager.sortCategory);

                // sort subcategories items
                category.subCategories = category.subCategories.map(subCategory => {
                    if (subCategory.items.length > 0) {                        
                        subCategory.items.sort(ApiManager.sortItems);
                    }    
                    return subCategory;
                })                                                
            }

            return category;
        })
     

        return sortedCategories;
    }

    static login = async (username, password) => {                
        let postBody = {username, password};
       
        let response = await fetch(`${SERVER_API_URL}/users/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postBody)
        });
        

        let result = await response.json();        
        return result;        
    }
}