import React, {useEffect, useState} from "react";
import styles from './Item.module.css';
import { withRouter } from 'react-router-dom';
import { MdEdit } from "react-icons/md";

export const QTY_OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
export const EDIT_QTY_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

const Item = withRouter(props => {
    let {item, updateQty, root, edit, history, category} = props;    
    
    useEffect(() => {      
    }, []);

    const editItem = () => {
        if (root) {
            history.push(`/admin/editCategoryItem/${item._id}`);
        } else {
            history.push(`/admin/editSubCategoryItem/${item._id}`);
        }
        
    }

    const SameNameItemLayout = () => {
        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {item.unitTxt != "" && 
                <>
                <span className={styles.price}>容量</span>
                <span className={styles.price}>{item.unitTxt}</span>
                </>
                }
                <span className={styles.price}></span>
                <span className={styles.price}>{item.price > 1 ? `$${item.price}` : "缺貨"}</span>
                {!edit && item.price > 1 &&
                <>
                <span className={styles.price}>數量:</span>
                <span>
                    <select value={item.selectedQty} onChange={ele => updateQty(item._id, QTY_OPTIONS[ele.target.selectedIndex])} className={styles.dropdown}>
                        {QTY_OPTIONS.map(option => (
                            <option value={option} key={option}>{option}</option>
                        ))}                                    
                    </select>
                </span>  
                </>              
                }
                {edit &&
                <span style={{display: 'inline-flex', flex: 1, justifyContent: 'flex-end'}}>
                    <span onClick={editItem} style={{display: 'inline-flex', cursor: 'pointer'}}>
                        <span style={{fontSize: '20px'}}><MdEdit /></span>
                        <span>修改</span>
                    </span>
                </span>                                    
                }
            </div>
        )

    }

    const DifferentNameItemLayout = () => {
        return (
            <>
            <div style={{paddingTop: '5px', color: '#479cc8'}}>{item.nameChi}</div>                    
            <div style={{paddingTop: '5px', color: '#479cc8'}}>{item.nameEn}</div>             
            <div style={{display: "inline-flex", paddingTop: '5px'}}>
                <span className={styles.price}>容量</span>
                <span className={styles.price}>{item.unitTxt}</span>
                <span className={styles.price}></span>
                <span className={styles.price}>{item.price > 1 ? `$${item.price}` : "缺貨"}</span>
                {item.price > 1 &&
                <>
                <span className={styles.price}>數量:</span>
                <span>
                    <select value={item.selectedQty} onChange={ele => updateQty(item._id, QTY_OPTIONS[ele.target.selectedIndex])} className={styles.dropdown}>
                        {QTY_OPTIONS.map(option => (
                            <option value={option} key={option}>{option}</option>
                        ))}                                    
                    </select>
                </span>  
                </>
                }
                {edit ? 
                <span style={{display: 'inline-flex', flex: 1, justifyContent: 'flex-end'}}>
                    <span onClick={editItem} style={{display: 'inline-flex', cursor: 'pointer'}}>
                        <span style={{fontSize: '20px'}}><MdEdit /></span>
                        <span>修改</span>
                    </span>
                </span>                                    
                :
                ""}
            </div>                
            </>
        )
    }

    let sameNameAsCategory = (item.nameChi === category.nameChi) && (item.nameEn === category.nameEn);

    return (        
        <div className={styles.container}>               
            {sameNameAsCategory ? <SameNameItemLayout /> : <DifferentNameItemLayout />}                                                
        </div>        
    )
});

export default Item;