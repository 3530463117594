import React, {useState} from "react";
import ApiManager from '../../manager/ApiManager';
import styles from "./AddNewCategory.module.css";
import { useParams } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';


const AddNewCategory = withRouter(props => {
    let {categoryId} = useParams();
    let [newCategory, setNewCategory] = useState({nameChi: "", nameEn: "", parentCategoryId: categoryId});

    const addCategory = async () => {
        let response = await ApiManager.addCategory(newCategory.nameChi, newCategory.nameEn, newCategory.parentCategoryId);
        if (response.result) {
            alert("成功新增種類");
            props.history.goBack();
        } else {
            alert("提交失敗");
        }
    }
    
    const cancel = async () => {
        props.history.goBack();
    }
    
    return (
        <div style={{padding: '10px 0px'}}>
            <div className={styles.title}>
                {categoryId == null ? "新增種類" : "新增子種類"}
            </div>
            <div className={styles.row}>
                中文名稱: <input type="text" value={newCategory.nameChi} onChange={val => {                            
                    setNewCategory({...newCategory, nameChi: val.target.value})}
                }/>
            </div>
            <div className={styles.row}>
                英文名稱: <input type="text" value={newCategory.nameEn} onChange={val => {
                    setNewCategory({...newCategory, nameEn: val.target.value})}
                }/>
            </div>
            <div className={styles.btns}>
                <CustomButton onClick={addCategory}>提交</CustomButton>
                <CustomButton onClick={cancel} style={{marginLeft: '10px'}}>取消</CustomButton>
            </div>     
        </div> 
    )
});


export default AddNewCategory;