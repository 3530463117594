import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../App";
import styles from "./OrderForm.module.css";
import {FREE_SHIPPING_ENTITLED_AMOUNT, DISCOUNT_SHIPPING_ENTITLED_AMOUNT, DISCOUNTED_SHIPPING_FEE, SHIPPING_FEE, DELIVERY_METHOD, WHATSAPP_CONTACT, PAYME_LINK} from "../../Constants"
import { EDIT_QTY_OPTIONS } from '../Item/Item';
import { RiSave3Line, RiDeleteBin6Line } from "react-icons/ri";

const OrderForm = () => {
    let {state, dispatch} = useContext(AppContext);
    let [selectedItems, setSelectedItems] = useState([]);
    let [total, setTotal] = useState(0);
    let [name, setName] = useState("");    
    let [deliveryMethod, setDeliveryMethod] = useState(0);
    let [deliveryDetails, setDeliveryDetails] = useState("");
    let [mobile, setMobile] = useState("");
    let [shippingFee, setShippingFee] = useState("");    
    let currentSelectedItems = useRef([]);

    const roundToTwoDp = (num) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    }

    useEffect(() => {        
        

        let selectedItems = getSelectedItems();
        selectedItems = selectedItems.map((selectedItem, idx) => { 
            let edit = false;
            if (currentSelectedItems.current[idx] != null) {
                edit = currentSelectedItems.current[idx].edit;
            };            
            return {...selectedItem, edit: edit};
        });
        currentSelectedItems.current = selectedItems;
        if (selectedItems != null) {
            // let tmpTotal = selectedItems.reduce((acc, item) => {
            //     return acc + (item.selectedQty * item.price);
            // }, 0);
        
            // let tmpShippingFee;
            // if (tmpTotal >= FREE_SHIPPING_ENTITLED_AMOUNT) {
            //     tmpShippingFee = 0;
            // } else if (tmpTotal >= DISCOUNT_SHIPPING_ENTITLED_AMOUNT) {
            //     tmpShippingFee = DISCOUNTED_SHIPPING_FEE;
            // } else {
            //     tmpShippingFee = SHIPPING_FEE;
            // }

            let result = getAmount(selectedItems);
            
            setShippingFee(result.shippingFee);
            setTotal(result.total);
            setSelectedItems(selectedItems);
        }
        /*
        let {categories} = state;

        if (categories != null) {
            
            
            let selectedItems = categories.reduce((acc, category) => {
                let selectedItems = category.items.filter(item => item.selectedQty > 0);                        
                acc.push(...selectedItems);
                return acc;            
            }, []); 

            let tmpTotal = selectedItems.reduce((acc, item) => {
                return acc + (item.selectedQty * item.price);
            }, 0);
        
            setTotal(tmpTotal);
            setSelectedItems(selectedItems);
        }
        */
    }, [state])

    const getSelectedItems = () => {
        let {categories} = state;

        let reduceItems = (acc, category) => {
            let selectedItems = category.items.filter(item => item.selectedQty > 0);                        
            acc.push(...selectedItems);            
            if (category.subCategories.length > 0) {
                let subCatSelectedItems = category.subCategories.reduce(reduceItems, []);
                acc.push(...subCatSelectedItems);
            }

            return acc;            
        };

        let selectedItems = [];
        if (categories != null) {
            selectedItems = categories.reduce(reduceItems, []);
        }        
        return selectedItems;
    }

    const getAmount = (selectedItems) => {
        let tmpTotal = selectedItems.reduce((acc, item) => {
            return roundToTwoDp(acc + roundToTwoDp(item.selectedQty * item.price));
        }, 0);
    
        let tmpShippingFee;
        if (tmpTotal >= FREE_SHIPPING_ENTITLED_AMOUNT) {
            tmpShippingFee = 0;
        } else if (tmpTotal >= DISCOUNT_SHIPPING_ENTITLED_AMOUNT) {
            tmpShippingFee = DISCOUNTED_SHIPPING_FEE;
        } else {
            tmpShippingFee = SHIPPING_FEE;
        }        

        return {total: tmpTotal, shippingFee: tmpShippingFee};
    }

    const submit = () => {
                        
        let selectedItems = getSelectedItems();
        
        let initContent = `收件人姓名: ${name}\n收件人電話: ${mobile}\n送貨方式: ${DELIVERY_METHOD[deliveryMethod]}\n收件地址: ${deliveryDetails}\n已選擇的貨品:\n`;

        let content = selectedItems.reduce((acc, selectedItem, idx) => {
            acc += `${idx + 1}. ${selectedItem.nameChi} ${selectedItem.unitTxt} x ${selectedItem.selectedQty} = $${roundToTwoDp(selectedItem.selectedQty * selectedItem.price)}\n`
            return acc;
        }, initContent);

        content += `--------------------------------------\n`;
        content += `💁🏻‍♀️ 已選貨品合共$ ${total}\n`;

        if (shippingFee > 0) {
            content += `+ 🚚 SF順豐運費: $${shippingFee}\n`;
        } else {
            content += `+ 🚚 SF順豐運費: 免費$${shippingFee}\n`;
        }

        content += `🎀 = 訂單總額: $${total + shippingFee}\n`;

        content += `\n按此鏈結付款: ${PAYME_LINK}`
        console.log(content);


        let encodedContent = window.encodeURIComponent(content);
        let whatsappLink = `https://wa.me/${WHATSAPP_CONTACT}?text=${encodedContent}`;

        
        // console.log(test);
        
        window.open(whatsappLink, '_blank');
    }


    const editItemQty = (itemId) => {
        let newSelectedItems = [...selectedItems];
        let targetItem = newSelectedItems.find(selectedItem => selectedItem._id == itemId);
        targetItem.edit = true;

        currentSelectedItems.current = newSelectedItems;
        console.log(currentSelectedItems.current);

        setSelectedItems(newSelectedItems);
    }

    const updateItemQty = (itemId, qty) => {
        let newSelectedItems = [...selectedItems];
        let targetItem = newSelectedItems.find(selectedItem => selectedItem._id == itemId);
        targetItem.selectedQty = qty;        
        targetItem.edit = true;

        currentSelectedItems.current = newSelectedItems;

        setSelectedItems(newSelectedItems);

        dispatch({type: "updateItem", payload: targetItem});
    }

    const finishItem = (itemId) => {
        let newSelectedItems = [...selectedItems];
        let targetItem = newSelectedItems.find(selectedItem => selectedItem._id == itemId);
        targetItem.edit = false;

        setSelectedItems(newSelectedItems);

        dispatch({type: "updateItem", payload: targetItem});
    }

    const deleteItem = (itemId) => {
        let newSelectedItems = [...selectedItems];
        let targetItem = newSelectedItems.find(selectedItem => selectedItem._id == itemId);
        targetItem.edit = false;
        targetItem.selectedQty = 0;        

        setSelectedItems(newSelectedItems);

        dispatch({type: "updateItem", payload: targetItem});
    }

    return (
        <>
        <div className={styles.section}>
            <div className={styles.title}>🚚 送貨資料</div>
            <div className={styles.fieldLabel}>送貨方式</div>
            <div>
                <select className={styles.dropdown} onChange={val => setDeliveryMethod(val.target.value)} value={deliveryMethod}>   
                    {DELIVERY_METHOD.map((method, idx) => (
                        <option value={idx} key={idx}>{method}</option>
                    ))}                                                     
                </select>
            </div>

            {deliveryMethod == 0 && 
                <>
                <div className={styles.fieldLabel}>收件地址: </div>
                <div>
                    <textarea className={styles.textarea} value={deliveryDetails} onChange={val => setDeliveryDetails(val.target.value)}></textarea>
                </div>
                </>
            }
            {deliveryMethod == 1 && 
                <>
                <div className={styles.fieldLabel}>順豐智能櫃點碼 (<a href="https://www.sf-express.com/hk/tc/dynamic_function/S.F.Network/EF-Locker/" target="_blank">詳細列表</a>)</div>
                <div>
                    <input type="text" className={styles.input} value={deliveryDetails} onChange={val => setDeliveryDetails(val.target.value)}></input>
                </div>
                </>
            }
            {deliveryMethod == 2 && 
                <>
                <div className={styles.fieldLabel}>順豐點 (<a href="https://www.sf-express.com/hk/tc/dynamic_function/S.F.Network/SF_store_address/" target="_blank">詳細列表</a>)</div>
                <div>
                    <input type="text" className={styles.input} value={deliveryDetails} onChange={val => setDeliveryDetails(val.target.value)}></input>
                </div>
                </>
            }
            {deliveryMethod == 3 && 
                <>
                <div className={styles.fieldLabel}>順豐服務中心地址 (<a href="https://www.sf-express.com/hk/tc/dynamic_function/S.F.Network/SF_service_center_address/" target="_blank">詳細列表</a>)</div>
                <div>
                    <input type="text" className={styles.input} value={deliveryDetails} onChange={val => setDeliveryDetails(val.target.value)}></input>
                </div>
                </>
            }

            <div className={styles.fieldLabel}>收件人姓名: </div>
            <div><input type="text" className={styles.input} onChange={val => setName(val.target.value)}/></div>

            <div className={styles.fieldLabel}>收件人電話: </div>
            <div><input type="text" className={styles.input} onChange={val => setMobile(val.target.value)}/></div>
        </div>
        <div className={styles.section}>
            <div className={styles.title}>🛒 我的訂單</div>            
            <div className={styles.order}>     
                <div>
                    {total > 0 && selectedItems && selectedItems.map((selectedItem, idx) => (
                        selectedItem.edit ? 
                        <div className={styles.subTotalItem} key={selectedItem._id}>
                            {idx + 1}. {selectedItem.nameChi} {selectedItem.unitTxt} x
                            <select value={selectedItem.selectedQty} onChange={ele => updateItemQty(selectedItem._id, EDIT_QTY_OPTIONS[ele.target.selectedIndex])} style={{paddingLeft: '2px'}}>
                                {EDIT_QTY_OPTIONS.map(option => (
                                    <option value={option} key={option}>{option}</option>
                                ))}                                    
                            </select>                        
                            = ${roundToTwoDp(selectedItem.selectedQty * selectedItem.price)}
                            <span onClick={() => finishItem(selectedItem._id)} className={styles.editBtn}><RiSave3Line /></span>
                            <span onClick={() => deleteItem(selectedItem._id)} className={styles.editBtn}><RiDeleteBin6Line /></span>
                        </div>
                        :
                        <div className={styles.subTotalItem} key={selectedItem._id}>{idx + 1}. {selectedItem.nameChi} {selectedItem.unitTxt} x {selectedItem.selectedQty} = ${roundToTwoDp(selectedItem.selectedQty * selectedItem.price)} <span onClick={() => editItemQty(selectedItem._id)} role="img" style={{cursor: 'pointer'}}>📝</span></div>
                    ))}
                    
                    {total > 0 &&
                    <div>--------------------------------------</div>
                    }
                    {total > 0 &&
                    <div>💁🏻‍♀️ 已選貨品合共$ {total}</div>
                    }
                    <div>                                                
                        {total > 0 && shippingFee == 0 &&                         
                        <>
                        <div className={styles.shippingFeeRow} style={{alignSelf: 'flex-start'}}>+ 🚚 SF順豐運費: <span style={{color: 'green'}}>免費$0</span></div>
                        <div style={{display: 'flex',alignItems: 'center', flexDirection: 'column', padding: '15px 0'}}>
                            <div className={styles.shippingFeeRow} style={{color: 'green', fontSize: '15px'}}>✅你已訂購滿指定金額，可享免費送貨服務</div>                                            
                        </div>
                        </>
                        }     

                        {total > 0 && shippingFee == DISCOUNTED_SHIPPING_FEE &&                     
                        <>
                        <div className={styles.shippingFeeRow}>+ 🚚 SF順豐運費: <span style={{color: 'green'}}>優惠價${shippingFee}</span></div>
                        <div style={{display: 'flex',alignItems: 'center', flexDirection: 'column', padding: '15px 0'}}>
                            <div className={styles.shippingFeeRow}>🔔 <span style={{color: 'red', textDecoration: 'underline', fontSize: '15px'}}>訂購多${FREE_SHIPPING_ENTITLED_AMOUNT - total} 可享免費送貨服務</span></div>
                        </div>    
                        </>
                        }                                         

                        {total > 0 && shippingFee == SHIPPING_FEE &&                     
                        <>
                        <div className={styles.shippingFeeRow}>+ 🚚 SF順豐運費: ${shippingFee}</div>
                        <div style={{display: 'flex',alignItems: 'center', flexDirection: 'column', padding: '15px 0'}}>
                            <div className={styles.shippingFeeRow}>🔔 <span style={{color: 'red', textDecoration: 'underline', fontSize: '15px'}}>訂購多${DISCOUNT_SHIPPING_ENTITLED_AMOUNT - total} 可享優惠運費${DISCOUNTED_SHIPPING_FEE}</span></div>
                            <div className={styles.shippingFeeRow} style={{color: 'red', textDecoration: 'underline', fontSize: '15px'}}>或</div>
                            <div className={styles.shippingFeeRow} style={{color: 'red', textDecoration: 'underline', fontSize: '15px'}}>訂購多${FREE_SHIPPING_ENTITLED_AMOUNT - total} 可享免費送貨服務</div>
                        </div>
                        </>
                        }                                                                 
                    </div>

                    {total > 0 &&
                    <div className={styles.total}>🎀 = 訂單總額: ${total + shippingFee}</div>
                    }                                              
                
                    {total > 0 && deliveryDetails != '' && name != '' && mobile != '' &&
                    <div onClick={submit} className={styles.btn}>提交訂單</div>
                    }

                    {(total > 0 && (deliveryDetails == '' || name == '' || mobile == '')) &&
                    <div className={styles.btn}>請填上送貨資料</div>
                    }

                    {total == 0 && 
                    <div className={styles.total}>你還未選擇任何貨品</div>
                    }               
                </div>
            </div>
        </div>

        
        </>
    )
}

export default OrderForm;