import React, { useEffect, useState, useContext } from "react";
import ApiManager from "../../manager/ApiManager";
import Category from "../Category/Category";
import { AppContext } from "../../App";
import styles from './ProductList.module.css'
import Header from "../Header/Header";

const ProductList = () => {    
    let {state, dispatch} = useContext(AppContext);
    useEffect(() => {
        const getInfo = async() => {
            let categories = await ApiManager.getCategories();            
            dispatch({type: "setupCategories", payload: categories});
        }
        getInfo();
    }, [])
    return (
        <>        
        <Header></Header>        
        <div className={styles.title}>📦 訂購貨品</div>
        <div className={styles.category}>
            {state.categories != null && state.categories.map(category => (
                <Category category={category} key={category._id} layer={0}></Category>
            ))}        
        </div>
        </>
    )
}

export default ProductList;