import React, {useContext} from "react";
import styles from "./HeadBanner.module.css";
import { withRouter, NavLink } from 'react-router-dom';
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { AppContext } from '../../App';

const HeadBanner = () => {
    let {state, dispatch} = useContext(AppContext);

    const goToTop = () => {
        window.scrollTo(0, 0);
    } 

    const goToBottom = () => {
        window.scrollTo(0,document.body.scrollHeight);
    }

    const logout = () => {
        dispatch({type: "logout"});
    }

    return (
        <div style={{position: 'fixed', left: 0, top: 0, width: '100%', background: '#FFF', display: 'flex', justifyContent: 'center', padding: '10px 0 0 0'}}>        
            <a href="//www.facebook.com/poise.hk" target="_blank"><img src="./img/poise_cropped.jpg" height={100}/></a>
            { state.authenticated && 
            <div style={{position: 'absolute', left: 10, height: '110px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div>歡迎, {state.username} (<span onClick={logout}>登出</span>)</div>
            </div>
            }
            <div style={{position: 'absolute', right: 30, height: '110px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <div className={styles.arrowBtn} onClick={goToTop}><TiArrowSortedUp /></div>
                <div className={styles.arrowBtn} onClick={goToBottom}><TiArrowSortedDown /></div>
            </div>
        </div>
    )
}

export default HeadBanner;