import React, {useEffect, useState, useContext} from "react";
import Item from '../Item/Item';
import { AppContext } from "../../App";
import styles from './Category.module.css'
import { MdEdit, MdAddBox } from "react-icons/md";
import { FaCaretRight, FaCaretDown } from "react-icons/fa";
import { Redirect, withRouter } from "react-router-dom";

const Category = withRouter((props) => {    
    let {state, dispatch} = useContext(AppContext);
    let {category, layer, edit, history} = props;
    let {_id: id, items} = category;
    let [visible, setVisible] = useState(false);    
    
    useEffect(() => {        
    }, []);

    const updateQty = (itemId, qty) => {        
        let targetItem = items.find(item => (item._id == itemId));
        targetItem.selectedQty = qty;
       
        dispatch({type: 'updateItem', payload: targetItem});        
    }

    const toggle = () => {                
        setVisible(!visible);
    }

    const editCategory = () => {
        if (layer == 0) {
            history.push(`/admin/editCategory/${id}`);
        } else {
            history.push(`/admin/editSubCategory/${id}`);
        }
    }

    const addSubCategory = () => {
        history.push(`/admin/addNewCategory/${id}`);
    }

    const addNewItem = () => {
        if (layer === 0) {
            history.push(`/admin/addNewCategoryItem/${id}`);                
        } else {            
            history.push(`/admin/addNewSubCategoryItem/${id}`);            
        }
    }

    return (
        <>
        <div className={styles.category}>
            {category != null &&
                <>
                <div className={styles.titleRow}>
                    <span>{visible ? <FaCaretDown /> : <FaCaretRight />}</span>
                    <span onClick={toggle} style={{cursor: 'pointer'}}>
                        {layer == 0 ?
                        <>
                        <span className={styles.name}>{category.nameChi}</span>
                        <span className={styles.name}>{category.nameEn}</span>
                        </>
                        :
                        <>
                        <span className={styles.name}>{category.nameEn} </span>
                        <span className={styles.name}>{category.nameChi}</span>
                        </>
                        }
                    </span>
                    <span style={{display: 'flex', alignItems: 'center', marginLeft: '10px', flex: 1, justifyContent: 'flex-end'}}>
                    {edit &&                        
                        <span style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}} onClick={editCategory}><span style={{display: 'flex', fontSize: '20px'}}><MdEdit /> </span><span>修改</span></span>                    
                    }
                    {edit && layer < 1 &&
                        <span style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}} onClick={addSubCategory}><span style={{display: 'flex', fontSize: '20px'}}><MdAddBox /> </span><span>新增子類別</span></span>
                    }
                    {edit &&                        
                        <span style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}} onClick={addNewItem}><span style={{display: 'flex', fontSize: '20px'}}><MdAddBox /> </span><span>新增貨物</span></span>                    
                    }                        
                    </span>                    
                </div>
                {/* list current category items */}
                <div className={styles.itemContainer}>
                {visible && category.items != null && category.items.map((item, idx) => {                    
                    return(                         
                        <Item item={item} updateQty={updateQty} key={item._id} root={layer == 0} edit={edit} category={category}></Item>                                                
                    )
                })}                
                </div>
                </>                        
            }
            {category != null && visible && category.subCategories != null && category.subCategories.map((subCategory, idx) => { 
                return (  
                    <div style={{paddingLeft: '20px', marginTop: '3px'}} key={idx}>
                        <Category category={subCategory} key={subCategory._id} layer={layer + 1} edit={edit}></Category>
                    </div>
                )
            })}
        </div>
        </>
    )
});

export default Category;